<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="newsletter">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div
                class="form-inline navbar-search"
                style="justify-content: start"
              >
                <div class="input-group">
                  <input
                    name="title"
                    class="form-control bg-light border-0 small"
                    placeholder="Search..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    v-model="filter"
                    v-on:keyup="search"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-search" @click="searchFor">
                      <i class="fas fa-search fa-sm"> </i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <span style="white-space: nowrap" class="mr-2"
                  >Filter User:</span
                >
                <b-form-select
                  id="verified"
                  style="width: 90%"
                  size="sm"
                  v-model="selected_user"
                  value-field="value"
                  text-field="text"
                  @change="fetchData(selected_user)"
                  :options="filter_user_options"
                >
                </b-form-select>
              </div>
            </div>
            <div class="table-responsive mb-0" id="campaign">
              <b-table
                responsive="sm"
                striped
                bordered
                :items="tableData.data"
                :per-page="0"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="table-bordered table-hover"
                :key="key"
              >
                <template #cell(show_details)="row">
                  <i
                    class="edit-i"
                    style="cursor: pointer"
                    :class="
                      row.detailsShowing ? 'mdi mdi-minus' : 'ri ri-edit-2-fill'
                    "
                    @click="
                      row.toggleDetails();
                      mciDetails(row);
                    "
                  ></i>
                </template>
                <template v-slot:cell(verified)="row">
                  <div v-if="row.item.verified == 0">
                    <span class="p-1 badge badge-warning">Pending</span>
                  </div>
                  <div v-if="row.item.verified == 1">
                    <span class="p-1 badge badge-success">Verified</span>
                  </div>
                  <div v-else-if="row.item.verified == 2">
                    <span class="p-1 badge badge-info">Not verified</span>
                  </div>
                  <div v-if="row.item.verified == 3 || row.item.verified == 4">
                    <span class="p-1 badge badge-secondary">Processing</span>
                  </div>
                  <div
                    v-if="
                      row.item.verified == 5 ||
                      row.item.verified == 6 ||
                      row.item.verified == 7
                    "
                  >
                    <span class="p-1 badge badge-danger">Block user</span>
                  </div>
                  <div v-if="row.item.verified == 8">
                    <span class="p-1 badge badge-warning">Demo account</span>
                  </div>
                </template>
                <template v-slot:cell(speciality)="row">
                  <span v-if="row.item.speciality">{{
                    row.item.speciality
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(email)="row">
                  <span v-if="row.item.email !== null">{{
                    row.item.email
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(member_id)="row">
                  <div>
                    {{ row.item.member_id }}
                    <!-- {{row.item.sr_no + 1}} -->
                  </div>
                </template>
                <template v-slot:cell(is_prime)="row">
                  <template v-if="row.item.is_prime == 1">
                    <div>Prime</div>
                  </template>
                  <template v-else>
                    <div>Not Prime</div>
                  </template>
                </template>
                <template v-slot:cell(mobile_number)="row">
                  <div v-if="!isClickedPhone || row.item.member_id != index">
                    {{ row.item.mobile_number }}
                  </div>
                  <div v-else>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="row.item.mobile_number"
                      ></b-form-input>
                      <b-input-group-append is-text>
                        <div size="sm" @click="submitMobile(row)">
                          <i class="mdi mdi-send-check"></i>
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </template>
                <template v-slot:cell(is_lead_profile_approved)="row">
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateLeadStatus(row.item.member_id)"
                    v-model="row.item.is_lead_profile_approved"
                    v-if="row.item.show_lead_profile_approve"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(phone_no_verified)="row">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <i
                        v-if="row.item.phone_no_verified == 1"
                        class="mdi mdi-power"
                        style="color: #1cbb8c; font-size: 20px"
                      ></i>
                      <i
                        v-else
                        class="mdi mdi-power"
                        style="color: red; font-size: 20px"
                      ></i>
                    </div>
                    <div v-if="row.item.phone_no_verified == 0">
                      <b-button
                        size="sm"
                        pill
                        variant="primary"
                        @click="editPhone(row.item.member_id)"
                        ><i class="mdi mdi-square-edit-outline"></i
                      ></b-button>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(email)="row">
                  <div v-if="!isClickedEmail || row.item.member_id != index">
                    {{ row.item.email }}
                  </div>
                  <div v-else>
                    <b-input-group size="sm">
                      <b-form-input v-model="row.item.email"></b-form-input>
                      <b-input-group-append is-text>
                        <div size="sm" @click="submitEmail(row)">
                          <i class="mdi mdi-email-send"></i>
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </template>
                <template v-slot:cell(email_verify)="row">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <i
                        v-if="row.item.email_verify == 1"
                        class="mdi mdi-power"
                        style="color: #1cbb8c; font-size: 20px"
                      ></i>
                      <i
                        v-else
                        class="mdi mdi-power"
                        style="color: red; font-size: 20px"
                      ></i>
                    </div>
                    <div v-if="row.item.email_verify == 0">
                      <b-button
                        size="sm"
                        pill
                        variant="primary"
                        @click="editEmail(row.item.member_id)"
                        ><i class="mdi mdi-square-edit-outline"></i
                      ></b-button>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(name)="row">
                  <div v-if="!isClickedName || row.item.member_id != index">
                    {{ row.item.first_name }} {{ row.item.last_name }}
                    <div class="ml-2" @click="editName(row.item.member_id)">
                      <i
                        class="mdi mdi-square-edit-outline"
                        style="color: #5664d2; font-size: 20px"
                      ></i>
                    </div>
                  </div>
                  <div v-else>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="row.item.first_name"
                      ></b-form-input>
                      <b-form-input v-model="row.item.last_name"></b-form-input>
                      <b-input-group-append is-text>
                        <div size="sm" @click="submitName(row)">
                          <i class="mdi mdi-email-send"></i>
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </template>

                <template #row-details="row">
                  <b-card class="mci">
                    <div style="font-size: 16px; font-weight: 600" class="mb-4">
                      MCI Verification
                    </div>
                    <div class="more-colmns">
                      <template>
                        <div class="d-flex align-items-center">
                          <div class="text-sm-right mr-2">
                            <b>Mci No:</b>
                          </div>
                          <template
                            v-if="
                              !isClickedMciNo || row.item.member_id != index
                            "
                          >
                            <div>{{ row.item.mci_no }}</div>
                            <div
                              class="ml-2"
                              @click="editMciNo(row.item.member_id)"
                            >
                              <i
                                class="mdi mdi-square-edit-outline"
                                style="color: #5664d2; font-size: 20px"
                              ></i>
                            </div>
                          </template>
                          <template v-else>
                            <b-input-group size="sm" style="width: 40%">
                              <b-form-input
                                v-model="row.item.mci_no"
                              ></b-form-input>
                              <b-input-group-append is-text>
                                <div size="sm" @click="submitMciNo(row)">
                                  <i class="mdi mdi-file-check-outline"></i>
                                </div>
                              </b-input-group-append>
                            </b-input-group>
                          </template>
                        </div>
                      </template>
                      <template>
                        <div class="d-flex align-items-center">
                          <div class="text-sm-right mr-2">
                            <b>Mci Year:</b>
                          </div>
                          <template
                            v-if="
                              !isClickedMciYear || row.item.member_id != index
                            "
                          >
                            <div>{{ row.item.mci_year }}</div>
                            <div
                              class="ml-2"
                              @click="editMciYear(row.item.member_id)"
                            >
                              <i
                                class="mdi mdi-square-edit-outline"
                                style="color: #5664d2; font-size: 20px"
                              ></i>
                            </div>
                          </template>
                          <template v-else>
                            <b-input-group size="sm" style="width: 40%">
                              <b-form-input
                                v-model="row.item.mci_year"
                              ></b-form-input>
                              <b-input-group-append is-text>
                                <div size="sm" @click="submitMciYear(row)">
                                  <i class="mdi mdi-file-check-outline"></i>
                                </div>
                              </b-input-group-append>
                            </b-input-group>
                          </template>
                        </div>
                      </template>
                      <template>
                        <div class="d-flex align-items-center">
                          <div class="text-sm-right mr-2">
                            <b>Field Mr Referral:</b>
                          </div>
                          <template
                            v-if="
                              !isClickedMciReferrel ||
                              row.item.member_id != index
                            "
                          >
                            <div>{{ row.item.referred_by }}</div>
                            <div
                              v-if="row.item.referred_by == null"
                              class="ml-2"
                              @click="editMciRefferrel(row.item.member_id)"
                            >
                              <i
                                class="mdi mdi-square-edit-outline"
                                style="color: #5664d2; font-size: 20px"
                              ></i>
                            </div>
                          </template>
                          <template v-else>
                            <b-input-group size="sm" style="width: 40%">
                              <b-form-input
                                v-model="row.item.referred_by"
                              ></b-form-input>
                              <b-input-group-append is-text>
                                <div size="sm" @click="submitMciReferrel(row)">
                                  <i class="mdi mdi-file-check-outline"></i>
                                </div>
                              </b-input-group-append>
                            </b-input-group>
                          </template>
                        </div>
                      </template>
                      <div class="d-flex align-items-center">
                        <div class="text-sm-right mr-2">
                          <b>City:</b>
                        </div>
                        <div>{{ row.item.mci_city }}</div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="text-sm-right mr-2">
                          <b>State:</b>
                        </div>
                        <template
                          v-if="
                            !isClickedMciState || row.item.member_id != index
                          "
                        >
                          <template v-if="row.item.mci_state">
                            {{ row.item.mci_state }}
                          </template>
                          <template v-else>
                            <div>Please Select State</div>
                          </template>
                          <div
                            class="ml-2"
                            @click="editMciState(row.item.member_id)"
                          >
                            <i
                              class="mdi mdi-square-edit-outline"
                              style="color: #5664d2; font-size: 20px"
                            ></i>
                          </div>
                        </template>
                        <template v-else>
                          <b-form-select
                            size="sm"
                            v-model="row.item.mci_state"
                            :options="mciStates"
                            @change="changeMciState(row)"
                          >
                          </b-form-select>
                        </template>
                      </div>
                      <div class="d-flex align-items-center">
                        <div style="width: 20%" class="mr-2">
                          <b>Verified:</b>
                        </div>
                        <b-form-select
                          id="verified"
                          size="sm"
                          v-model="row.item.verified"
                          value-field="value"
                          text-field="text"
                          :disabled="row.item.verified === 1"
                          @change="changeStatus(row)"
                          :options="verified_options"
                        >
                        </b-form-select>
                      </div>
                      <div
                        class="d-flex align-items-center"
                        v-if="row.item.verified_by"
                      >
                        <div class="text-sm-right mr-2">
                          <b>Verified By:</b>
                        </div>
                        <div>
                          {{ row.item.verified_by }}
                          <span v-if="row.item.admin_name"
                            >({{ row.item.admin_name }})</span
                          >
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="mr-2"><b>View Document:</b></div>
                        <div
                          v-if="row.item.mci_document"
                          v-b-modal="'myModal'"
                          class="ml-2"
                          user="'item'"
                          @click="
                            sendInfo(
                              row.item.mci_document.mci_reg,
                              row.item.mci_image_path
                            )
                          "
                        >
                          <i
                            class="mdi mdi-file-eye-outline"
                            style="color: #1cbb8c; font-size: 20px"
                          ></i>
                        </div>
                        <div v-if="row.item.no_document == 1" class="ml-2">
                          Call to Doctor
                        </div>
                        <template
                          v-if="
                            !isClickedCallDoctor || row.item.member_id != index
                          "
                        >
                          <b-button
                            class="ml-2"
                            v-if="row.item.verify_by_id"
                            @click="editDigiMrId(row.item.member_id)"
                            :disabled="admin_id != row.item.verify_by_id"
                            style="background: white; border: unset"
                          >
                            <i
                              class="mdi mdi-phone-outgoing"
                              style="color: #5664d2; font-size: 20px"
                            ></i>
                          </b-button>
                          <b-button
                            class="ml-2"
                            v-else
                            @click="editDigiMrId(row.item.member_id)"
                            :disabled="mod_val != row.item.mod"
                            style="background: white; border: unset"
                          >
                            <i
                              class="mdi mdi-phone-outgoing"
                              style="color: #5664d2; font-size: 20px"
                            ></i>
                          </b-button>
                        </template>
                        <template v-else>
                          <b-input-group
                            size="sm"
                            class="ml-2"
                            style="width: 30%"
                          >
                            <b-form-input
                              v-model="row.item.digiMR_id"
                            ></b-form-input>
                            <b-input-group-append is-text>
                              <div size="sm" @click="submitCallToDoctor(row)">
                                <i class="mdi mdi-file-phone-outline"></i>
                              </div>
                            </b-input-group-append>
                          </b-input-group>
                        </template>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="text-sm-right">
                          <b>Mci Reg Status:</b>
                        </div>
                        <div>
                          <i
                            v-if="row.item.mci_reg_status == 1"
                            class="mdi mdi-power"
                            style="color: #1cbb8c; font-size: 20px"
                          ></i>
                          <i
                            v-else-if="row.item.mci_reg_status == 0"
                            class="mdi mdi-power"
                            style="color: red; font-size: 20px"
                          ></i>
                          <i
                            v-else
                            class="mdi mdi-power"
                            style="color: red; font-size: 20px"
                          ></i>
                        </div>
                      </div>
                      <div
                        class="d-flex align-items-center"
                        v-if="row.item.mci_reg_match_percentage"
                      >
                        <div class="text-sm-right">
                          <b>Mci Reg match percentage:</b>
                        </div>
                        <div>
                          {{ row.item.mci_reg_match_percentage }}
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="text-sm-right">
                          <b>Mci State Status:</b>
                        </div>
                        <div>
                          <i
                            v-if="row.item.mci_state_status == 1"
                            class="mdi mdi-power"
                            style="color: #1cbb8c; font-size: 20px"
                          ></i>
                          <i
                            v-else-if="row.item.mci_state_status == 0"
                            class="mdi mdi-power"
                            style="color: red; font-size: 20px"
                          ></i>
                          <i
                            v-else
                            class="mdi mdi-power"
                            style="color: red; font-size: 20px"
                          ></i>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div style="width: 30%">
                          <b>MCI Upload:</b>
                        </div>
                        <b-form-file
                          size="sm"
                          ref="mci_file"
                          @change="uploadMciFile($event, row)"
                        >
                        </b-form-file>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="text-sm-right">
                          <b>Mci Year Status:</b>
                        </div>
                        <div>
                          <i
                            v-if="row.item.mci_year_status == 1"
                            class="mdi mdi-power"
                            style="color: #1cbb8c; font-size: 20px"
                          ></i>
                          <i
                            v-else-if="row.item.mci_year_status == 0"
                            class="mdi mdi-power"
                            style="color: red; font-size: 20px"
                          ></i>
                          <i
                            v-else
                            class="mdi mdi-power"
                            style="color: red; font-size: 20px"
                          ></i>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="text-sm-right">
                          <b>Mci Name Status:</b>
                        </div>
                        <div>
                          <i
                            v-if="row.item.mci_name_status == 1"
                            class="mdi mdi-power"
                            style="color: #1cbb8c; font-size: 20px"
                          ></i>
                          <i
                            v-else-if="row.item.mci_name_status == 0"
                            class="mdi mdi-power"
                            style="color: red; font-size: 20px"
                          ></i>
                          <i
                            v-else
                            class="mdi mdi-power"
                            style="color: red; font-size: 20px"
                          ></i>
                        </div>
                      </div>
                      <!-- <div class="d-flex align-items-center">
                                    <div style="width: 30%">
                                       <b>Qualification:</b>
                                    </div>
                                    <b-form-select size="sm" :value="row.item.qualification != null ? row.item.qualification : ''" value-field="qualification_master_ref_no" text-field="course_name" :options="row.item.qualifications_by_speciality" @change="setQualification(row,$event)">
                                       </b-form-select>
                                 </div> -->
                      <div
                        v-b-modal="'noteModal'"
                        class="ml-2"
                        user="'item'"
                        @click="sendInfoNote(row.item.member_id)"
                      >
                        <i
                          class="mdi mdi-note-multiple"
                          style="color: #1cbb8c; font-size: 20px"
                          >({{ row.item.mci_note_count }})</i
                        >
                      </div>
                    </div>
                  </b-card>
                </template>
              </b-table>
              <div class="no_content" v-if="tableData.data.length == 0">
                <div style="display: grid">
                  <img src="noData.svg" alt="" />
                  <span class="no_content_text">No Content Available</span>
                </div>
              </div>
              <b-modal id="myModal">
                <div>
                  <img
                    :src="image"
                    class="transition"
                    v-bind:style="{ transform: `rotate(${deg}deg)` }"
                    alt="refresh-icon-btn"
                  />
                </div>
                <template v-slot:modal-footer class="p-0">
                  <b-button @click="rotation">Rotate</b-button>
                </template>
              </b-modal>
              <b-modal id="noteModal" size="lg">
                <h2 class="text-center py-3">Notes</h2>

                <div class="container py-2 mt-4 mb-4">
                  <div
                    class="row"
                    v-for="message in messages"
                    :key="message.id"
                  >
                    <div
                      class="col-auto text-center flex-column d-none d-sm-flex"
                    >
                      <div class="row h-50">
                        <div class="col">&nbsp;</div>
                        <div class="col">&nbsp;</div>
                      </div>
                      <h5 class="m-2">
                        <span class="badge1 badge-pill bg-light border"
                          >&nbsp;</span
                        >
                      </h5>
                      <div class="row h-50">
                        <div class="col border-right">&nbsp;</div>
                        <div class="col">&nbsp;</div>
                      </div>
                    </div>
                    <div class="col py-2">
                      <div class="card">
                        <div class="card-body">
                          <div class="float-right text-muted">
                            {{ getSessionStart(message.created_at) }}
                          </div>
                          <h4 class="card-title">{{ message.mci_note }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-slot:modal-footer class="p-0">
                  <b-input-group
                    @submit.prevent="sendMessage('out')"
                    id="person2-form"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label for="person2-input" class="mb-0 mr-2">You: </label>
                    <b-input
                      v-model="message"
                      id="person2-input"
                      type="text"
                      placeholder="Type your message"
                      class="mr-2"
                    ></b-input>
                    <b-button @click="storeNotes(id, message)" type="submit"
                      >Send</b-button
                    >
                  </b-input-group>
                </template>
              </b-modal>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.transition {
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.more-colmns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 10px;
}

.badge {
  padding: 8px 10px;
  font-size: 12px;
}
.badge1 {
  padding: 8px 10px;
  font-size: 7px;
}
.chat-area {
  /*   border: 1px solid #ccc; */
  background: white;
  height: 50vh;
  padding: 1em;
  overflow: auto;
  max-width: 350px;
  margin: 0 auto 2em auto;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3);
}
.message-out {
  background: #407fff;
  color: white;
  margin-left: 50%;
  border-radius: 10px;
  padding: 0.5em;
}
</style>
<style lang="scss" scoped>
.tab-options {
  color: #3169aa !important;

  position: relative;

  &::after {
    content: "";
    position: absolute;
    border-bottom: 5px solid #0a0606 !important;
    left: 0;
    bottom: -3px;
    width: 100%;
  }
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import mciVerficationMixin from "../../../mixins/ModuleJs/mci-verification";

export default {
  mixins: [MixinRequest, mciVerficationMixin],
  data() {
    return {
      title: "MCI Member Verification",
      mci_file: {},
      editPhoneNo: null,
      deg: 0,
      image: "",
      index: 0,
      isClickedPhone: false,
      isClickedEmail: false,
      isClickedName: false,
      isClickedMciNo: false,
      isClickedMciYear: false,
      isClickedMciReferrel: false,
      isClickedMciState: false,
      isClickedCallDoctor: false,
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  methods: {
    rotation() {
      this.deg += 90;
    },
    editPhone(member_id) {
      this.isClickedEmail = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      (this.isClickedMciReferrel = false), (this.isClickedMciState = false);
      this.isClickedCallDoctor = false;
      this.isClickedPhone = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    editEmail(member_id) {
      this.isClickedPhone = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedEmail = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    editMciNo(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciNo = true;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedName = false;
      this.index = member_id;
    },
    editMciState(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciState = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    editDigiMrId(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    sendInfo(image, image_path) {
      this.image = image_path + image;
      console.log(image);
      console.log(this.image);
    },
    sendInfoNote(val) {
      this.id = val;
      this.fetchNotes(this.id);
    },
    sendInfoQualification(id) {
      this.mid = id;
      console.log(id);
      console.log(this.mid);
    },
    editMciYear(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = true;
      this.isClickedMciReferrel = false;
      this.isClickedName = false;
      this.index = member_id;
    },
    editMciRefferrel(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = true;
      this.isClickedName = false;
      this.index = member_id;
    },
    editName(member_id) {
      this.isClickedPhone = false;
      this.isClickedEmail = false;
      this.isClickedMciState = false;
      this.isClickedCallDoctor = false;
      this.isClickedMciNo = false;
      this.isClickedMciYear = false;
      this.isClickedMciReferrel = false;
      this.isClickedName = true;
      this.index = member_id;
    },
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
